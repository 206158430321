<template lang="pug">
  .auto-set-inventory(id="auto-set-inventory")
    BAlert.warning(
      show
      variant="warning"
      :title="$t('inventory_management.inventory_long_period.modal_notice')"
    )
      | {{ $t('inventory_management.inventory_for_future.modal_notice') }}
    AppOverlayLoader(:state="settingsLoading")
    #search-settings
      BTooltip(
        v-if="disabledInputs"
        ref="tooltip"
        target="search-settings"
        variant="secondary"
        triggers="hover"
        placement="top"
      )
        span {{ unsavedChangesTooltip }}
      AppSearch(
        :class="{ 'disabled': disabledInputs }"
        :disabled="disabledInputs"
        :value="filters.search_value"
        @update="applySearch({ search_value: $event })"
      )
    #add-setting
      BTooltip(
        v-if="disabledInputs"
        target="add-setting"
        variant="secondary"
        triggers="hover"
        placement="top"
      )
        span {{ unsavedChangesTooltip }}
      AppButton.add-setting-button(
        title="inventory_management.inventory_for_future.add_setting"
        icon="plus"
        type="other"
        :disabled="disabledInputs"
        @click="handleAddNewSetting"
      )
    SettingItem(
      v-for="(setting, index) in processedSettings"
      :key="setting.id"
      :setting="setting"
      :is-expanded="getToggler(setting).state"
      :is-expandable="!hasUpdatedSetting"
      :references-items="references"
      :references-loading="referencesLoading"
      :intersection-data="intersectionData"
      :selected-days-options="selectedDaysOptions[setting.reference.id]"
      :inventory-fields="inventoryFields"
      @collapse="handleCollapse(setting)"
      @cancel="handleCancel(setting)"
      @expand="handleExpand(setting)"
      @change-setting="changeSetting({ index, item: $event })"
      @delete-setting="deleteSettingAction(index, $event)"
    )
</template>

<script>
  // store modules
  import autoSetInventoryForFutureModule from "@/config/store/inventory_management/auto_set_for_future"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { bus } from "@/config"
  import { DEFAULT_SETTING_OBJECT, INVENTORY_FIELDS } from "./constants"
  import { every, reduce, some, isEmpty, forEach, first, find, reject, cloneDeep, map, intersection } from "lodash-es"
  import {
    settingIds,
    totalSettings,
    collapsedSettingsGrouped,
    selectedDaysOptions,
    getOrderedCarClassIds,
    getKeysByValues
  } from "@/helpers/auto-set-inventory"
  import { toggleize, resetTogglers } from "@/helpers/toggler"
  import { appDebounce } from "@/helpers/common"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  const autoSetInventoryForFutureMixin = withStoreModule(autoSetInventoryForFutureModule, {
    name: "AutoSetInventoryForFuture",
    resetState: true,
    readers: {
      filters: "filters",
      settings: "items",
      settingsLoading: "loading"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      addNewSetting: "ADD_ITEM",
      changeSetting: "SET_ITEM_BY_INDEX",
      setSettings: "SET_ITEMS"
    },
    actions: {
      fetchSettings: "FETCH_ITEMS",
      createSetting: "CREATE_ITEM",
      updateSetting: "UPDATE_ITEM",
      deleteSetting: "DELETE_ITEM",
      deleteUnsavedSetting: "DELETE_UNSAVED_SETTING"
    }
  })

  export default {
    components: {
      SettingItem: () => import("./SettingItem"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppButton: () => import("@/components/elements/AppButton/WithIcon"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [autoSetInventoryForFutureMixin],

    props: {
      references: {
        type: Array,
        default: () => new Array()
      },
      referencesLoading: Boolean,
      inventoryFields: {
        type: Array,
        default: () => [...INVENTORY_FIELDS]
      }
    },

    watch: {
      hasUpdatedSetting(hasChanges) {
        bus.emit("set-modal-unsaved-changes", hasChanges)
      }
    },

    created() {
      this.debouncedFetchSettings = appDebounce(() => this.fetchSettings())
    },

    mounted() {
      this.fetchSettings().then(() => this.setInitialSettings())
    },

    beforeDestroy() {
      resetTogglers()
    },

    data() {
      return {
        initialSetting: null,
        initialSettings: []
      }
    },

    computed: {
      settingIds,
      totalSettings,
      collapsedSettingsGrouped,
      selectedDaysOptions,

      unsavedChangesTooltip() {
        return this.$t("actions.unsaved_tooltip")
      },

      disabledInputs() {
        return this.hasUnsavedSetting || this.hasUpdatedSetting
      },

      hasUnsavedSetting({ settings }) {
        return !every(settings, "id")
      },

      hasUpdatedSetting({ settings }) {
        return some(settings, "_updated")
      },

      togglers({ settings }) {
        return reduce(
          settings,
          (obj, setting) => {
            const key = setting.id || setting._addedAt
            obj[key] = toggleize(`inventorySettingToggler-${key}`, false)
            return obj
          },
          {}
        )
      },

      expandedSetting() {
        return find(this.processedSettings, setting => this.getToggler(setting).state)
      },

      expandedSettingCarClassIds({ expandedSetting }) {
        return isEmpty(expandedSetting) ? [] : getOrderedCarClassIds(expandedSetting)
      },

      currentReferenceSettings() {
        return this.collapsedSettingsGrouped[this.expandedSetting.reference.id]
      },

      intersectionData({ expandedSetting }) {
        if (isEmpty(expandedSetting)) {
          return {}
        }

        const intersections = reduce(
          this.expandedSettingCarClassIds,
          (obj, expandedSettingCarClassId) => {
            obj[expandedSettingCarClassId] = []
            forEach(this.currentReferenceSettings, setting => {
              const carClassIds = map(setting.car_classes, "id").toString()
              if (carClassIds.includes(expandedSettingCarClassId)) {
                const settingDays = getKeysByValues(setting.days_options)
                const expandedSettingDays = getKeysByValues(expandedSetting.days_options)
                obj[expandedSettingCarClassId].push(...intersection(settingDays, expandedSettingDays))
              }
            })
            return obj
          },
          {}
        )

        return { referenceId: this.expandedSetting.reference.id, intersections }
      },

      processedSettings() {
        return this.settings.map(setting => {
          if (isOrganizationWithSharedInventory() && setting.synced_group === false) {
            return {
              ...setting,
              active: false
            }
          } else {
            return setting
          }
        })
      }
    },

    methods: {
      applySearch(filters) {
        this.setFilters(filters)
        this.debouncedFetchSettings()
      },

      setInitialSettings() {
        this.initialSettings = cloneDeep(this.settings)
      },

      handleAddNewSetting() {
        this.addNewSetting(DEFAULT_SETTING_OBJECT)

        this.handleExpand(first(this.settings))
      },

      deleteSettingAction(index, setting) {
        if (setting.id) {
          this.deleteSetting({ item: setting }).then(() => this.setInitialSettings())
        } else {
          this.deleteUnsavedSetting({ index })
        }
      },

      getToggler(setting) {
        return this.togglers[setting.id || setting._addedAt]
      },

      handleCollapse(setting) {
        if (!isEmpty(setting.reference) && setting._updated) {
          this.handleSaveSetting(setting)
        }

        this.getToggler(setting).disable()
      },

      handleCancel(setting) {
        this.getToggler(setting).disable()

        const settings = reduce(
          this.settings,
          (res, fetchedSetting, index) => {
            if (!setting.id && index === 0) {
              return res
            }
            if (fetchedSetting.id === setting.id) {
              return [...res, this.initialSetting]
            }
            return [...res, fetchedSetting]
          },
          []
        )

        this.setSettings(settings)
      },

      handleSaveSetting(setting) {
        const action = setting.id ? this.updateSetting : this.createSetting

        return action(setting).then(() => this.setInitialSettings())
      },

      handleExpand(setting) {
        // for cases when user try to open another existing setting
        // need to remove unsaved new setting if it exists
        if (setting.id) {
          const savedSettings = reject(this.settings, setting => !setting.id)

          this.setSettings(savedSettings)
        }

        this.initialSetting = setting
        this.collapseAll()
        this.getToggler(setting).enable()
      },

      collapseAll() {
        forEach(this.togglers, toggler => toggler.disable())
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/auto-set-inventory-for-future.sass"

  .auto-set-inventory
    height: 70vh
    width: 920px

    .app-search
      width: 100%
      margin-bottom: 25px

      &.disabled
        cursor: not-allowed
        opacity: 0.4

        ::v-deep
          input
            cursor: not-allowed

    .warning
      text-align: center
      margin-bottom: 25px
      font-size: 0.8rem !important

    #add-setting
      margin-bottom: 25px

      .add-setting-button
        border: 1px solid $border-color
        border-radius: 3px
        height: 45px
        width: 100%

        ::v-deep
          .app-button
            &-content
              color: $default-black
</style>
